import React from "react"
import axios from "axios"
import Select from "react-select"
import { select } from "../utils/select-styles"

const API_ENDPOINT = "https://test-api.trtlogistics.com"
//const API_ENDPOINT = "https://localhost:44373"

export class ShippingCalculator extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      type: "portToPort",
      ports: [],
      auctions: [],
      countries: [],
      destinations: [],
      selectedCountryCode: "",
      selectedCountryName: "",
      selectedPortId: "",
      selectedPortName: "",
      selectedAuctionId: "",
      selectedAuctionName: "",
      selectedDestinationId: "",
      selectedDestinationName: "",
      //carsCount: 1,
      openedTab: 0,
      quote: null,
      oceanQuote: null,
      message: null,
    }
    this.firstName = React.createRef()
    this.lastName = React.createRef()
    this.email = React.createRef()
    this.phone = React.createRef()
    this.vehicle = React.createRef()
    this.originRef = React.createRef()
    this.countryRef = React.createRef()
    this.cityRef = React.createRef()
  }

  componentDidMount() {
    this.getPorts()
    this.getCountries()
  }

  getCountries() {
    axios
      .get(`${API_ENDPOINT}/api/geo/countries`, {
        headers: {
          Accept: "application/json",
        },
      })
      .then((result) => {
        const { state } = this
        this.setState({ ...state, countries: result.data })
      })
  }

  getAuctions() {
    axios
      .get(`${API_ENDPOINT}/api/geo/auctionlocations`, {
        headers: {
          Accept: "application/json",
        },
      })
      .then((result) => {
        const { state } = this
        this.setState({ ...state, auctions: result.data })
      })
  }

  getPorts() {
    axios
      .get(`${API_ENDPOINT}/api/geo/ports`, {
        headers: {
          Accept: "application/json",
        },
      })
      .then((result) => {
        const { state } = this
        this.setState({ ...state, ports: result.data })
      })
}

  getDestinations(countryCode) {
    axios
      .get(`${API_ENDPOINT}/api/geo/destinations/country/${countryCode}`, {
        headers: {
          Accept: "application/json",
        },
      })
      .then((result) => {
        const { state } = this

        this.setState({ ...state, destinations: result.data })
      })
  }

  getQuote(event) {
    event.preventDefault()
    if (this.state.type === "portToPort") {
      axios
        .get(
          `${API_ENDPOINT}/api/calculator/quoteocean?portId=${this.state.selectedPortId}&destinationId=${this.state.selectedDestinationId}`,
          {
            headers: {
              Accept: "application/json",
            },
          }
        )
        .then((result) => {
          const { state } = this
          const quote = JSON.parse(result.data)

          if (!quote) {
            this.setState({
              ...state,
              quote: null,
              openedTab: 1,
              message: null,
            })
          } else {
            this.setState({ ...state, quote, openedTab: 1, message: null })
          }
        })
    } else {
      if(this.state.selectedCountryName === 'United States'){
        axios
          .get(
            `${API_ENDPOINT}/api/calculator/quoteinland?locationId=${this.state.selectedAuctionId}&portId=${this.state.selectedPortId}`,
            {
              headers: {
                Accept: "application/json",
              },
            }
          )
          .then((result) => {
            const { state } = this
            const quote = JSON.parse(result.data);
            const oceanQuote = null;

            if (!quote) {
              this.setState({
                ...state,
                quote: null,
                openedTab: 1,
                message: null,
              })
            } else {
              this.setState({ ...state, quote, oceanQuote, openedTab: 1, message: null })
            }
          })
      } else {
        axios
          .get(
            `${API_ENDPOINT}/api/calculator/quoteInlandOcean?auctionLocationId=${this.state.selectedAuctionId}&destinationId=${this.state.selectedDestinationId}`,
            {
              headers: {
                Accept: "application/json",
              },
            }
          )
          .then((result) => {
            const { state } = this
            const quotes = JSON.parse(result.data)
            const quote = quotes.InlandQuote;
            const oceanQuote = quotes.OceanQuote;

            if (!quote || !oceanQuote) {
              this.setState({
                ...state,
                quote: null,
                openedTab: 1,
                message: null,
              })
            } else {
              this.setState({ ...state, quote, oceanQuote, openedTab: 1, message: null })
            }
          })
      }
    }
  }

  submitQuote(event) {
    event.preventDefault();
    const bodyFormData = new FormData();
    bodyFormData.append('Lead_Source', 'Calculator');
    bodyFormData.append('Type', 'Quote Request');
    bodyFormData.append('Company', 'Empty');
    bodyFormData.append('Last_Name', this.lastName.current.value);
    bodyFormData.append('First_Name', this.firstName.current.value);
    bodyFormData.append('Email', this.email.current.value);
    bodyFormData.append('Phone', this.phone.current.value);
    const currentDate = (new Date()).toLocaleDateString('en-US');
    bodyFormData.append('Quote date', currentDate);
    bodyFormData.append('Vehicle', this.vehicle.current.value);
    if(this.state.type === 'portToPort'){
      bodyFormData.append('From port', this.state.selectedPortName);
      bodyFormData.append('To', `${this.state.selectedDestinationName}, ${this.state.selectedCountryName}`);
    } else {
      bodyFormData.append('From auction', this.state.selectedAuctionName);
      if(this.state.selectedCountryName === 'United States'){
        bodyFormData.append('To', `${this.state.selectedPortName}, United States`);
      } else {
        bodyFormData.append('To', `${this.state.selectedDestinationName}, ${this.state.selectedCountryName}`);
      }
    }
    if(this.state.quote){
      const inlandRate = this.state.quote.rate ? this.state.quote.rate : 0;
      const oceanRate = this.state.oceanQuote.rate ? this.state.oceanQuote.rate : 0;
      const totalRate = inlandRate + oceanRate;
      const inlandMinDays = this.state.quote.transitDaysMin ? this.state.quote.transitDaysMin : 0;
      const inlandMaxDays = this.state.quote.transitDaysMax ? this.state.quote.transitDaysMax : 0;
      const oceanMinDays = this.state.oceanQuote.transitDaysMin ? this.state.oceanQuote.transitDaysMin : 0;
      const oceanMaxDays = this.state.oceanQuote.transitDaysMax ? this.state.oceanQuote.transitDaysMax : 0;
      const totalMinDays = inlandMinDays + oceanMinDays;
      const totalMaxDays = inlandMaxDays + oceanMaxDays;
      bodyFormData.append('Ground Shipping', `$${inlandRate}`);
      bodyFormData.append('Ocean Shipping', `$${oceanRate}`);
      bodyFormData.append('Total shipping', `$${totalRate}`);
      bodyFormData.append('Estimated Sailing Days', `${totalMinDays} – ${totalMaxDays} days`);
    }
    axios
      .post(
        `${API_ENDPOINT}/api/WebForms/Capture`,
        bodyFormData,
        {
          headers: {
            Accept: "application/json",
          },
        }
      )
      .then((result) => {
        this.setState({ message: { type: "success", text: "Request success" } })
      })
      .catch((error) => {
        this.setState({ message: { type: "danger", text: "Error sending quote" } });
      })
  }

  onPortChanged(event) {
    if(event)
      this.setState({ ...this.state, selectedPortId: event.value, selectedPortName: event.label })
  }

  onAuctionChanged(event) {
    if(event)
      this.setState({ ...this.state, selectedAuctionId: event.value, selectedAuctionName: event.label })
  }

  onCountryChanged(event) {
    this.cityRef.current.clearValue()
    if(event){
      const countryCode = event.value;
      const countryName = event.label;
      this.setState({
        ...this.state,
        destinations: [],
        selectedCountryCode: countryCode,
        selectedCountryName: countryName,
        selectedDestinationId: '',
        selectedDestinationName: ''
      })
      if (!(this.state.type === 'auctionToPort' && countryName === 'United States')) {
        this.getDestinations(countryCode);
      }
    }
  }

  onDestinationChanged(event) {
    if(event)
      this.setState({ ...this.state, selectedDestinationId: event.value, selectedDestinationName: event.label })
  }

  goToRateQuote() {
    if (this.state.quote) {
      this.setState({ ...this.state, openedTab: 1 })
    }
  }

  goBack(){
    this.setState({ ...this.state, openedTab: 0 });
  }

  onChangeType(event) {
    this.originRef.current.clearValue()
    if (event.target.value === "portToPort") {
      this.getPorts()
      this.setState({ ...this.state, type: event.target.value, selectedAuctionId: '', selectedAuctionName: '' })
      if(this.state.selectedCountryName === 'United States'){
        this.countryRef.current.clearValue()
        this.cityRef.current.clearValue()
      }
    } else {
      this.getAuctions()
      this.setState({ ...this.state, type: event.target.value, selectedPortId: '', selectedPortName: '' })
    }
  }

  displayTransitDays(quote) {
    if (quote) {
      const minTransitDays = quote.transitDaysMin;
      const maxTransitDays = quote.transitDaysMax;
      let counter;

      if (minTransitDays && maxTransitDays) {
        return ` (${minTransitDays} - ${maxTransitDays} days)`
      }

      if (minTransitDays && !maxTransitDays) {
        counter =  minTransitDays === 1 ? 'day': 'days';
        return ` (${minTransitDays} ${counter})`;
      }

      if (!minTransitDays && maxTransitDays) {
        counter = maxTransitDays === 1 ? 'day': 'days';
        return ` (${maxTransitDays} ${counter})`;
      }
    }
  }

  render() {
    const {
      ports,
      countries,
      auctions,
      destinations,
      selectedCountryCode,
      selectedCountryName,
      quote,
      oceanQuote,
      carsCount,
      openedTab,
      message,
      inlandQuote,
      seaQuote,
      type,
    } = this.state
    const { params = {} } = this.props
    return (
      <div className="panel panel-shipping-calculator p0">
        <div className="panel-body">
          <div className="shipping-calculator-wrapper">
            <h3 className="shipping-calculator-wrapper-header">
              {params.calculator_title || "Shipping Calculator"}
            </h3>
            {message ? (
              <div className={`alert alert-${message.type}`} role="alert" data-cy="error-area">
                {message.text}
              </div>
            ) : null}
            <div>
              <div className="tab-content">
                <div
                  role="tabpanel"
                  className={`tab-pane tab-pane-itinerary ${
                    openedTab === 0 ? "active" : ""
                  }`}
                >
                  <h4 className="text-center">Rate Quote</h4>
                  <div
                    className="d-flex"
                    onChange={(event) => this.onChangeType(event)}
                  >
                    <div className="mr15">
                      <label>
                        <input
                          name="type"
                          checked={type === "portToPort"}
                          type="radio"
                          value="portToPort"
                        />{" "}
                        Port To Port
                      </label>
                    </div>
                    <div>
                      <label>
                        <input
                          name="type"
                          checked={type === "auctionToPort"}
                          type="radio"
                          value="auctionToPort"
                          data-cy="ocean-radio"
                        />{" "}
                        Auction To Port{" "}
                      </label>
                    </div>
                  </div>
                  {type === "portToPort" ? (
                    <form onSubmit={(event) => this.getQuote(event)}>
                      <div className="mb15">
                        <label htmlFor="locationInput">
                          {params.calculator_location_title ||
                            "Vehicle location in the US"}
                        </label>
                        <div className="row">
                          <div className="col-xs-12">
                            <Select
                              ref = {this.originRef}
                              options={ports
                                .sort((a, b) => a.Name.localeCompare(b.Name))
                                .map((port) => ({
                                  value: port.Id,
                                  label: port.Name,
                                }))}
                              id="port-select"
                              styles={select}
                              required
                              placeholder={"Location"}
                              onChange={(event) => {
                                this.onPortChanged(event)
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="mb15">
                        <label htmlFor="destinationInput">
                          {params.calculator_destination_title ||
                            "Destination overseas"}
                        </label>
                        <div className="row mb15">
                          <div className="col-xs-6">
                            <Select
                              ref = {this.countryRef}
                              options={countries
                                .filter(item=>item.Name !== "United States")
                                .sort((a, b) => a.Name.localeCompare(b.Name))
                                .map((country) => ({
                                  value: country.StrCode,
                                  label: country.Name,
                                }))}
                              id="country-select"
                              styles={select}
                              required
                              placeholder={"Country"}
                              onChange={(event) => {
                                this.onCountryChanged(event)
                              }}
                            />
                          </div>
                          <div className="col-xs-6">
                            <Select
                              ref = {this.cityRef}
                              options={destinations
                                .sort((a, b) => a.Name.localeCompare(b.Name))
                                .map((destination) => ({
                                  value: destination.Id,
                                  label: destination.Name,
                                }))}
                              id="destination-select"
                              required
                              styles={select}
                              placeholder={"Destination"}
                              onChange={(event) => {
                                this.onDestinationChanged(event)
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <button
                        type="submit"
                        data-cy="calculate-button-ocean"
                        className="btn btn-primary w-100 mb15"
                      >
                        {params.calculate_button_calculate || "Calculate"}
                      </button>
                    </form>
                  ) : (
                    <form onSubmit={(event) => this.getQuote(event)}>
                      <div className="mb15">
                        <label htmlFor="locationInput">
                          {params.calculator_auction_title ||
                            "Auction in the US"}
                        </label>
                        <div className="row">
                          <div className="col-xs-12">
                            <Select
                              ref = {this.originRef}
                              options={auctions.map((auction) => ({
                                value: auction.Id,
                                label: auction.Name,
                              }))}
                              id="destination-location-select"
                              required
                              styles={select}
                              placeholder={"Location"}
                              onChange={(event) => {
                                this.onAuctionChanged(event)
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="mb15">
                        <label htmlFor="destinationInput">
                          {params.calculator_destination_title ||
                            "Destination overseas"}
                        </label>
                        <div className="row mb15">
                          <div className="col-xs-6">
                            <Select
                              ref = {this.countryRef}
                              options={countries
                                .sort((a, b) => a.Name.localeCompare(b.Name))
                                .map((country) => ({
                                  value: country.StrCode,
                                  label: country.Name,
                                }))}
                              id="country-select"
                              styles={select}
                              required
                              placeholder={"Country"}
                              onChange={(event) => {
                                this.onCountryChanged(event)
                              }}
                            />
                          </div>
                          <div className="col-xs-6">
                            {selectedCountryName === 'United States' ? (
                              <Select
                                ref = {this.cityRef}
                                options={ports
                                  .sort((a, b) => a.Name.localeCompare(b.Name))
                                  .map((port) => ({
                                    value: port.Id,
                                    label: port.Name,
                                  }))}
                                id="port-select"
                                styles={select}
                                required
                                placeholder={"Location"}
                                onChange={(event) => {
                                  this.onPortChanged(event)
                                }}
                              />
                            ) : (
                              <Select
                                ref = {this.cityRef}
                                options={destinations
                                  .sort((a, b) => a.Name.localeCompare(b.Name))
                                  .map((destination) => ({
                                    value: destination.Id,
                                    label: destination.Name,
                                  }))}
                                id="destination-port-select"
                                required
                                styles={select}
                                placeholder={"Destination"}
                                onChange={(event) => {
                                  this.onDestinationChanged(event)
                                }}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                      <button
                        type="submit"
                        data-cy="calculate-button-inland"
                        className="btn btn-primary w-100 mb15"
                      >
                        {params.calculate_button_calculate || "Calculate"}
                      </button>
                    </form>
                  )}
                </div>
                <div
                  role="tabpanel"
                  className={`tab-pane tab-pane-rate-quote ${
                    openedTab === 1 ? "active" : ""
                  }`}
                >
                  <div className="mb15" onClick={() => this.goBack()}>
                    <div className="btn btn-default" style={{padding: "5px", minWidth: "80px"}}>
                      ❮ Back
                    </div>
                  </div>
                  {quote ? (
                    <>
                      {this.state.type === "portToPort" ? (
                        <>
                          <div className="shipping-calculator-rate-quote">
                            <div className="type-transportation-block">
                            <span className="type-transportation-header type-delivery">
                              ocean
                              {this.displayTransitDays(quote)}
                            </span>
                              <span>{quote && quote.portOfExportFromId}</span>
                              <div className="arrow-i" />
                              <span>{quote && quote.destinationFromId}</span>
                            </div>
                            <div className="transit-block"/>
                          </div>
                          <div className="shipping-calculator-rate-quote">
                          </div>
                          <div className="shipping-calculator-rate-quote total">
                            <div className="type-transportation-block mb15">
                            <span className="type-transportation-header">
                              {params.calculator_total_cost ||
                                "Total Shipping Cost"}
                            </span>
                              <div className="price">${quote && quote.rate}</div>
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="shipping-calculator-rate-quote">
                            <div className="type-transportation-block">
                            <span className="type-transportation-header type-delivery">
                              inland
                              {this.displayTransitDays(quote)}
                              <div className="cost">${quote && quote.rate}</div>
                            </span>
                              <span>{quote && quote.auctionLocation}</span>
                              <div className="arrow-i" />
                              <span>{quote && quote.portOfExportFromId}</span>
                            </div>
                            <div className="transit-block"/>
                          </div>
                          {oceanQuote ? (
                            <>
                              <div className="shipping-calculator-rate-quote">
                                <div className="type-transportation-block">
                                  <span className="type-transportation-header type-delivery">
                                    ocean
                                    {this.displayTransitDays(oceanQuote)}
                                    <div className="cost">${oceanQuote && oceanQuote.rate}</div>
                                  </span>
                                  <span>{oceanQuote && oceanQuote.portOfExportFromId}</span>
                                  <div className="arrow-i" />
                                  <span>{oceanQuote &&  oceanQuote.destinationFromId}</span>
                                </div>
                                <div className="transit-block"/>
                              </div>
                              <div className="shipping-calculator-rate-quote total">
                                <div className="type-transportation-block mb15">
                                  <span className="type-transportation-header">
                                    {params.calculator_total_cost ||
                                      "Total Shipping Cost"}
                                  </span>
                                  <div className="price">${quote && oceanQuote && quote.rate + oceanQuote.rate}</div>
                                </div>
                              </div>
                            </>
                          ) : null}
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      <div className={'mb15'}>
                        We need a little more time to give you an exact quote for this destination.<br/>
                        Please proceed with Custom Quote request below
                      </div>
                    </>
                  )}
                  <form onSubmit={(event) => this.submitQuote(event)}>
                    <div className="mb15">
                      <label htmlFor="infoInput">
                        {params.calculator_contact_title || "Contact Info"}
                      </label>
                      <div className="row mb15">
                        <div className="col-xs-6">
                          <input
                            ref={this.firstName}
                            required
                            type="text"
                            className="form-control"
                            id="infoInput"
                            placeholder={
                              params.calculator_contact_first_name ||
                              "First Name"
                            }
                          />
                        </div>
                        <div className="col-xs-6">
                          <input
                            required
                            type="text"
                            ref={this.lastName}
                            className="form-control"
                            id="infoInput"
                            placeholder={
                              params.calculator_contact_last_name || "Last Name"
                            }
                          />
                        </div>
                      </div>
                      <div className="row mb15">
                        <div className="col-xs-6">
                          <input
                            required
                            type="email"
                            ref={this.email}
                            className="form-control"
                            id="infoInput"
                            placeholder={
                              params.calculator_contact_email || "Email"
                            }
                          />
                        </div>
                        <div className="col-xs-6">
                          <input
                            required
                            type="tel"
                            className="form-control"
                            ref={this.phone}
                            id="infoInput"
                            placeholder={
                              params.calculator_contact_telephone || "Phone (or WhatsApp)"
                            }
                          />
                        </div>
                      </div>
                    <div className="row">
                      <div className="col-xs-12">
                        <input
                          required
                          type="text"
                          ref={this.vehicle}
                          className="form-control"
                          id="infoInput"
                          placeholder={
                            params.calculator_contact_vehicle || "Freight info (make, model, year, VIN)"
                          }
                        />
                      </div>
                    </div>
                    </div>
                    <button type="submit" className="btn btn-primary w-100">
                      {params.calculator_button_request || (quote ? "Email me quote" : "Request custom quote")}
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
